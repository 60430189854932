import { CollapsableQuestion } from 'components/collapsableQuestion/collapsableQuestion';
import { StandardLayout } from 'layouts/standardLayout/standardLayout';
import { useFAQPage } from 'pageHooks/faqPageHooks';
import * as pageClasses from 'pages/faq.module.scss';
import * as React from 'react';
import { Helmet } from 'react-helmet';

const FAQPage: React.FC = () => {
  const { questionsWithAnswers } = useFAQPage();

  return (
    <main>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>ScheduleLab - FAQ</title>
        <meta charSet="utf-8" />
      </Helmet>

      <StandardLayout title="Frequently Asked Questions">
        <article className={pageClasses.questionsAndAnswers}>
          {questionsWithAnswers.map((questionWithAnswer) => (
            <CollapsableQuestion key={questionWithAnswer.question} {...questionWithAnswer} />
          ))}
        </article>
      </StandardLayout>
    </main>
  );
};

export default FAQPage;
