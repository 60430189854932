import { QuestionWithAnswer } from 'components/collapsableQuestion/collapsableQuestion';
import { StandardLink } from 'components/standardLink/standardLink';
import React from 'react';

type UseFAQPageReturn = {
  questionsWithAnswers: QuestionWithAnswer[];
};

export function useFAQPage(): UseFAQPageReturn {
  return {
    questionsWithAnswers: [
      {
        question: 'Why is my college not supported?',
        answer:
          'The ScheduleLab team is still very small. We are growing our list of supported colleges as fast as we can. In the meantime, we encourage you to request your college from within the app. We track how many students want a college and focus on the most requested ones first.',
      },
      {
        question: 'How do I save a schedule for later?',
        answer:
          'To save a schedule, simply tap the heart icon when viewing it. This will add it to your "Favorites" list on the home page, where you can view it anytime.',
      },
      {
        question: 'How do I change my college?',
        answer:
          'To change your college, go to Favorites > Settings > Reset App. This will clear any favorited schedules & return you to first time setup. From there you can once again search for your college.',
      },
      {
        question: 'How do I change which campuses to use?',
        answer:
          'If your college supports multiple campuses, you can toggle them in Favorites > Settings. By default we leave all campuses enabled, but this is sometimes undesirable.',
      },
      {
        question: 'Why do the generated schedules have strange names, such as Schedule 4E635D32?',
        answer:
          "Schedules are named like this for two reasons. First being that we wanted the names to always be the same, no matter who generates a schedule. So if your friend on another device has the same courses, their schedule 4E635D32 would be the same as yours. Naming them like this also allows our algorithm to work more quickly since it doesn't have to worry about numbering them in order.",
      },
      {
        question: 'Why did the app stop generating schedules after the first 1000ish?',
        answer:
          "In order to keep the app operating at peak performance, we pause our algorithm after about 1000 schedules are generated. Our algorithm will generate the best schedules based on your preferences first so you can find the one you like best. As you scroll down the list, we will continue to generate schedules though if you haven't found the perfect one.",
      },
      {
        question: 'Something is wrong with the ScheduleLab catalog!',
        answer:
          "If you experience discrepancies in ScheduleLab's data (missing courses, incorrect vacancies, etc.), the first thing you should do is pull the latest catalog by going to Favorites > Settings > Update Catalogs. If you continue to notice missing courses, please reach out to support. ScheduleLab updates regularly to keep catalogs current, and sometimes this may just be from your college updating their course offerings recently.",
      },
      {
        question: 'What data does your app collect?',
        answer: (
          <>
            ScheduleLab currently collects only a small amount of data essential to running our app & tracking defects.
            With the exception of college requests, we keep all data anonymous. For more information, please refer to
            our <StandardLink to="/privacy">Privacy Policy</StandardLink>.
          </>
        ),
      },
    ],
  };
}
