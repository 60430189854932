import { RenderIf } from '@schedulelab/components/renderIf/renderIf';
import * as componentClasses from 'components/collapsableQuestion/collapsableQuestion.module.scss';
import * as React from 'react';
import { ReactNode } from 'react';
import { useToggle } from 'react-use';

export type QuestionWithAnswer = {
  question: string;
  answer: ReactNode;
};

export const CollapsableQuestion: React.FC<QuestionWithAnswer> = (props) => {
  const { question, answer } = props;
  const [isExpanded, toggleIsExpanded] = useToggle(true);
  return (
    <section>
      <section className={componentClasses.questionSection} onClick={toggleIsExpanded}>
        <h2 className={componentClasses.question}>{question}</h2>
        <p className={componentClasses.collapseStatus}>{isExpanded ? '-' : '+'}</p>
      </section>
      <RenderIf condition={isExpanded}>
        <p className={componentClasses.answer}>{answer}</p>
      </RenderIf>
    </section>
  );
};
